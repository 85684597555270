export type BehaviorSubject<T> = [
  /**
   * Emit a new value on the channel
   */
  /* dispatch */ (value: T) => void,
  /**
   * Add a listener, be provided with a unsubscribe callback
   * (duplicates not detected)
   */
  /* addListener */ (listener: (value: T) => void) => () => void,
  /**
   * Get the last value
   */
  /* getLatestValue */ () => T,
  /**
   * Get the number of current listeners
   */
  /* getListenerCount*/ () => number
];

/**
 * Very lightweight behavior subject. On each unsubscribe,
 * it emits an update to its parent with the latest value
 * and the number of active listeners.
 * @param current The initial value for the Behavior Subject
 * @param onUnsubscribe Callback to notify when an unsubscribe occurs
 * @param listeners Optionally initialize to an array of listeners
 */
export const createBehaviorSubject = <T>(
  current: T,
  listeners: Array<(value: T) => void> = []
): BehaviorSubject<T> => [
  /* dispatch */
  (value) => {
    current = value;
    listeners.forEach((listener) => listener(value));
  },

  /* addListener */
  (listener) => {
    listener(current);
    listeners.push(listener);
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },

  /* getLatestValue */
  () => current,

  /* getListenerCount */
  () => listeners.length
];
