// This error is thrown when the response is asserted to have status in 200..299 but does not
export class NotOkFetchResponse extends Error {
  readonly status: number;
  readonly url: string;
  static assert(response: Response, body?: any) {
    if (!response.ok) throw new NotOkFetchResponse(response, body);
  }
  constructor(public readonly response: Response, public readonly body?: any) {
    super('NotOkFetchResponse');
    this.status = this.response?.status;
    this.url = this.response?.url;
  }
}

// This error is thrown when the fetch implementation rejects.
export class RejectedFetchError extends Error {
  constructor(
    // A reference to the inner error that occurred in `fetch`
    public readonly innerError: Error,
    // A function that can be called to clear this error
    // so that it can be retried. Otherwise, future
    // requestors may receive this error in the future without
    // actually receiving a new response
    public readonly clearError: () => void
  ) {
    super('RejectedFetchError');
  }
}

// This is error thrown when receiving a 500+,
// only if using the "createStandardFetcher" service
export class ServiceUnavailableError extends Error {
  constructor(
    public readonly response: Response,
    public readonly url: string,
    public readonly status: number
  ) {
    super('ServiceUnavailableError');
  }
}

// This error is thrown when recieving an error for network connectivity
// issues, only if using the "createStandardFetcher" service
export class NetworkConnectivityError extends Error {
  constructor(public readonly innerError: Error, public readonly url: string) {
    super('NetworkConnectivityError');
  }
}
