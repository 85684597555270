import {
  withCache,
  withCallback,
  withFetchImpl,
  withResponseParser,
  withStandardFetchErrors
} from './fetch-decorators';
import {
  Fetcher,
  FetcherRefreshPolicy,
  RequestConfig,
  FetchImpl
} from './types';

export * from './fetch-decorators';
export * from './errors';
export * from './types';
export * from './form-request-creator';
export * from './json-request-creator';

/**
 * Create a fetcher
 * @param fetch The window.fetch impl. It can be decorated to use the extra
 * RequestConfig.opts
 * @param shouldBustCache This function can optionally be specified to override
 * the caching invalidation for each request. This function is called for each
 * response that has just been received, and then each request that might need
 * to be refreshed. This function is expected to return true for any that should
 * be refreshed.
 * @param getCacheId This function can optionally be specified to override the
 * cache key generation. By default, the JSON.stringify is used on the RequestConfig
 * to generate the deterministic cache key. If your application has other needs,
 * you can specify a different function; Perhaps a JSON.stringify that sorts objects
 * to ensure a higher level of determinism, or perhaps you have cache IDs built
 * in to each RequestConfig
 */
export const createFetcher = (
  fetchImpl: FetchImpl = fetch,
  shouldBustCache: FetcherRefreshPolicy = () => false,
  getCacheId: (requestConfig: RequestConfig) => string = (requestConfig) =>
    JSON.stringify(requestConfig)
): Fetcher =>
  /* prettier-ignore */
  withCache(shouldBustCache, getCacheId,
    withCallback(
      withResponseParser(
        withFetchImpl(
          withStandardFetchErrors(
            fetchImpl
          )
        )
      )
    )
  );
