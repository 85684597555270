import { RequestBodyRequestCreator } from './types';

/**
 * Use this to craft a RequestConfig using a JSON Request Body
 *
 * Example:
 * ```ts
 *   const createActivateRequest = (payload: ActivateRequest): RequestConfig<void> =>
 *     createJsonBodyRequestConfig({
 *       url: '/v2/activate_card',
 *       method: 'POST',
 *       mutating: true,
 *       json: payload
 *     });
 * ```
 *
 * The difference here is that you cannot specify a "body" string, rather only the "json" any prop
 */
export const createJsonBodyRequestConfig: RequestBodyRequestCreator<any> = ({
  json,
  ...requestConfig
}) => ({
  ...requestConfig,
  headers: {
    ...requestConfig.headers,
    'content-type': 'application/json'
  },
  body: JSON.stringify(json)
});
