import { BehaviorSubject, createBehaviorSubject } from './behavior-subject';
import {
  RejectedFetchError,
  ServiceUnavailableError,
  NetworkConnectivityError
} from './errors';
import {
  Fetcher,
  FetcherMakeRequest,
  FetcherRefreshPolicy,
  FetcherUpdateStatus,
  RequestConfig,
  FetchImpl
} from './types';

/*
 * This project uses wrapping decorators as the basis for its structure.
 * Essentially, there is a series of "middleware" that get called in order
 * and call and interact with the next one in the chain.
 *
 * Each middleware is called with the "next" function to call. In turn, it returns
 * its functionality as a return function that is called by the previous
 * one in the chain.
 *
 * This allows isolated functionality to exist in a given middleware while still
 * allowing flexibility to have
 *  * state outside a specific request
 *  * performing actions during, before and after its "next" one.
 *
 * Additionally, it allows the consumer to "swap" out one of the implementations
 * or perhaps add another implementation in between built-in functionality that
 * acts like a powerful notification / interaction system without incurring overhead
 * or consumers that may not need this flexibility.
 *
 * Also, if the consumer does not use each middleware decorator, they are able to
 * re-written more inline with each other automatically by the compiler while still
 * allowing better isolation and readability in the source code.
 *
 * Finally, we also remove the need for a `pipe()` runtime that interacts one transformer
 * with another what would provide little value in a project like this and likely only
 * incur type-script confusion.
 *
 * Simple Example:
 *
 * const withMessageArrays = (next: (message: string) => void) => {
 *   return (messages: string[]) => {
 *     next(messages.join('\n'));
 *   };
 * };
 *
 * const withTimeStamp = (next: (messages: string[]) => void) => {
 *   return (messages: string[]) => {
 *     next([
 *       new Date().toString(),
 *       ...messages
 *     ]);
 *   };
 * };
 *
 * // Call them passing in a final implementation function
 * const decoratedLogger = (
 *   withTimeStamp(
 *     withMessageArrays(
 *       (msg: string) => console.log(msg)
 *     )
 *   )
 * );
 *
 * decoratedLogger([ 'Hello', 'World', ]);
 * // Prints out
 * March 5, 2019, 12:59PM
 * Hello
 * World
 */

/**
 * This decorator wraps a fetch implementation and throws typed errors when detecting 500 errors,
 * and network connectivity errors.
 */
export const withStandardFetchErrors = (
  fetchImpl: FetchImpl
): FetchImpl => async (url, requestConfig) => {
  try {
    const response = await fetchImpl(url, requestConfig);
    if (response.status >= 500)
      throw new ServiceUnavailableError(response, url, response.status);
    return response;
  } catch (error) {
    // TypeError occurs when fetch fails, CORS or offline or otherwise
    // We want the actual "fetchImpl" to be able to throw its own errors also,
    // so we will rethrow them
    throw error instanceof TypeError
      ? new NetworkConnectivityError(error, url)
      : error;
  }
};

/**
 * This decorator is usually used closest to the passed in `fetch` instance. It converts
 * A `requestConfig: RequestConfig` to a `url: string, requestInit: RequestInit` to be
 * compatible with the built in fetch
 */
export const withFetchImpl = (
  next: (url: string, requestConfig: RequestConfig) => Promise<Response>
) => (requestConfig: RequestConfig): Promise<Response> =>
  next(requestConfig.url, requestConfig);

/**
 * This decorator is used while still in the Promise portion of this fetcher.
 * It looks at the RequestConfig > ResponseBodyType to see if it should parse to requested format json, text or blob.
 * if specified auto Or not specified anything the default will be auto and it looks at the response headers to see if it should parse json
 * then waits for it to be de-serialized before resolving. Otherwise it resolves
 * plain text directly
 */
export const withResponseParser = (
  next: ReturnType<typeof withFetchImpl>
) => async (requestConfig: RequestConfig): Promise<[Response, any]> => {
  const response = await next(requestConfig);
  let responseBodyType = requestConfig.responseBodyType;

  if (!responseBodyType || responseBodyType == 'auto') {
    const contentType = response.headers.get('content-type');
    responseBodyType =
      contentType && contentType.includes('application/json') ? 'json' : 'text';
  }
  const responseBody = await response[responseBodyType]();

  return [
    // Return the response
    response,
    // And the properly de-serialized body
    responseBody
  ];
};

/**
 * This decorator converts the promise portion of the flow to a
 * async callback. It also emits an update upon initial start to
 * indicate that "loading" is in progress
 */
export const withCallback = (next: ReturnType<typeof withResponseParser>) => (
  requestConfig: RequestConfig,
  shouldCache: boolean,
  callback: (status: FetcherUpdateStatus) => void
): FetcherMakeRequest => {
  let inflightRequest: Promise<[Response, any]> | null;
  return (bustCache = false) => {
    let promise = inflightRequest;
    if (bustCache || !inflightRequest || !shouldCache) {
      // We are going to start a new request
      callback([true, null, null, null]);
      // Save the promise used here for checking of current request
      promise = inflightRequest = next(requestConfig).then(
        (responseAndData) => {
          // Only emit callback data if we are the most recent request
          if (inflightRequest === promise) {
            callback([false, responseAndData[0], responseAndData[1], null]);
          }
          return responseAndData;
        },
        (error) => {
          // We create an error error that wraps this error. It has a lambda that
          // can clear out the error if it should be retried
          const rejectedError = new RejectedFetchError(error, () => {
            if (inflightRequest === promise) {
              inflightRequest = null;
              callback([false, null, null, null]);
            }
          });
          // Only emit callback data if we are the most recent request
          if (inflightRequest === promise) {
            // If we had an error and we own this promise, we need to clear
            // it out so others don't use this bad cached version
            callback([false, null, null, rejectedError]);
          }
          throw rejectedError;
        }
      );
      // We *tap* into the promise rejection to prevent "Unhandled Promise Rejections".
      // This can fail unit tests
      promise.catch(() => {
        // noop
      });
    }
    return promise as Promise<[Response, any]>;
  };
};

/**
 * The shape of the cache we use. Each entry is an tuple of:
 *  - an emitter
 *  - the function to make the request
 *  - and the request configuration
 */
interface WithCacheCache {
  [id: string]: [
    BehaviorSubject<FetcherUpdateStatus>,
    FetcherMakeRequest,
    RequestConfig
  ];
}

/**
 * This decorator takes a configuration refresh policy function.
 * This decorator "de-dupes" requests that come in that resolve to
 * the same cache id.
 */
export const withCache = (
  refreshPolicy: FetcherRefreshPolicy,
  getCacheId: (requstConfig: RequestConfig) => string,
  next: ReturnType<typeof withCallback>
): Fetcher => {
  const cache: WithCacheCache = Object.create(null);
  return (requestConfig) => {
    const id = requestConfig.mutating ? null : getCacheId(requestConfig);
    let entry = id ? cache[id] : null;
    const isCurrentCacheEntryOwner = () => !id || cache[id] === entry;
    if (!entry) {
      // Initial value for subscribers
      const initialCacheValue: FetcherUpdateStatus = [false, null, null, null];
      // Create an emitter
      const behaviorSubject = createBehaviorSubject<FetcherUpdateStatus>(
        initialCacheValue
      );

      // Start "next" and save its return value.
      // The update callback will be the dispatch of the BehaviorSubject
      const makeRequest = next(requestConfig, !!id, behaviorSubject[0]);
      const conditionallyMakeRequest: FetcherMakeRequest = (bustCache) => {
        // Do a dev-time check to make sure sure some is not requesting to update
        if (!isCurrentCacheEntryOwner()) {
          throw new Error(
            'Cannot make a new request for a disposed fetch instance'
          );
        }
        return makeRequest(bustCache);
      };

      entry = [behaviorSubject, conditionallyMakeRequest, requestConfig];

      // Save in the cache if it has a cache id
      if (id) cache[id] = entry;

      // Add a forever listener. On each status change, we check
      // to see if any other requests need to be refreshed
      // according to our cache policy. If the refresh policy
      // indicates a request is stale, we will refresh it on behalf
      // of the
      behaviorSubject[1](
        ([, response, data]: FetcherUpdateStatus) =>
          // If response is now available
          // Ask our refreshPolicy if we should refresh any other requests on account of this
          // new response.
          isCurrentCacheEntryOwner() &&
          response &&
          Object.entries(cache).forEach(
            ([
              otherCacheKey,
              [
                [, , getLatestOtherRequest, getNumberOfListenersOfOtherRequest],
                refreshOtherRequest,
                otherRequestConfig
              ]
            ]) => {
              const otherRequest = getLatestOtherRequest();
              const needsRefresh = refreshPolicy(
                // Pass along our request, our new response, and our new response body
                [requestConfig, response, data],
                // Pass along their request config for evaluation
                [otherRequestConfig, otherRequest]
              );
              // If our policy says this request is not stale, exit early
              if (!needsRefresh) return;

              // See if there ar more than one listeners since we have our own
              // forever listener
              if (getNumberOfListenersOfOtherRequest() > 1 || otherRequest[0]) {
                // If we do currently have subscribed listeners OR if the other request
                // is in-flight, we should refresh it.
                // When the request is in-flight, it is possible that no listener will be
                // subscribing to this request in the future, however, it is possible
                // that a listener is about to be added to the request, in the case of
                // frameworks like react wherein you cannot make a subscription during
                // a render and have it always be able to cleaned up, you must make it
                // during the use-effect which happens a bit later. Its not that bad
                // if we make an extra request in this race condition however, since it
                // is still a query, just the extra bandwidth.
                refreshOtherRequest(true);
              } else if (otherRequest[1] || otherRequest[3]) {
                // Check if the other request has ever been fired off
                // We can detect a request that has been fired when:
                //  - there is a most recent response available
                //  - there is a most recent error available
                delete cache[otherCacheKey];
              }
              // else {
              //   we don't need to do anything here
              //   since this condition indicates that a request placeholder
              //   has been made, but the request has never gone out, nor
              //   are there any listeners. There is no response data that
              //   needs to be cleaned up, and it is still semantically correct
              //   to register "query" that has never been requested yet. Leave
              //   those guys alone :-)
              // }
            }
          )
      );
    }

    // De-structure the cache entry to format our return tuple
    const [[, addListener, getLatestValue], request] = entry;
    return [request, addListener, getLatestValue];
  };
};
