import { RequestBodyRequestCreator } from './types';

export type FormEncodableBody = object;

const jsonToFormEncoded = (json: FormEncodableBody) => {
  const params = new URLSearchParams((json as any) || {});
  return params.toString();
};

/**
 * Use this to craft a RequestConfig with a form encoded body
 *  - Useful for sending requests to webapi v1 endpoints, which do not accept json bodys
 *
 * Example:
 * ```ts
 *   const createActivateRequest = (payload: ActivateRequest): RequestConfig<void> =>
 *     createFormBodyRequestConfig({
 *       url: '/v1/activate_card',
 *       method: 'POST',
 *       mutating: true,
 *       json: payload
 *     });
 * ```
 *
 * The difference here is that you cannot specify a "body" string, rather only the "json" any prop
 *
 * It's important to note that json property values should not be objects, as to avoid passing `propertyName=%5Bobject+Object%5D` as the request body
 */
export const createFormBodyRequestConfig: RequestBodyRequestCreator<FormEncodableBody> = ({
  json,
  ...requestConfig
}) => ({
  ...requestConfig,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    ...requestConfig.headers
  },
  body: jsonToFormEncoded(json)
});
